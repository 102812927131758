import * as RadixAvatar from '@radix-ui/react-avatar';

import SvgIcon from 'components/ui/svg_icon';
import { cx } from 'lib/utils';

import styles from './avatar.module.scss';

export const Root = ({ options = { rounded: 'full', size: 'md' }, ...props }) => (
  <RadixAvatar.Root
    {...props}
    className={cx(
      styles.avatar,
      options?.outline && styles['avatar--outline'],
      options?.rounded && styles[`avatar--rounded-${options.rounded}`],
      options?.size && styles[`avatar--size-${options.size}`],
      props?.className
    )}
  />
);

export const Fallback = ({ delayMs = 250, ...props }) => (
  <RadixAvatar.Fallback delayMs={delayMs} {...props} className={styles.fallback}>
    <SvgIcon icon="image-solid" />
  </RadixAvatar.Fallback>
);

export const Avatar = {
  Root,
  Image: RadixAvatar.Image,
  Fallback,
};
