import * as React from 'react';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { motion } from 'framer-motion';
import NextLink from 'next/link';
import ReactMarkdown from 'react-markdown';

import { Avatar } from 'components/common-n4/avatar';
import { Breadcrumbs } from 'components/common-n4/breadcrumbs';
import ImageWrapper from 'components/common-n4/image';
import { ShareButtons } from 'components/common-n4/share-buttons';
import { Skeleton } from 'components/common-n4/skeleton';
import { useApiData } from 'hooks/use_api_data';
import logger from 'lib/logger';
import { findBloks, sbSidebar } from 'lib/storyblok';
import { cx, formatDate } from 'lib/utils';

import styles from './article.module.scss';
import markdownStyles from './markdown.module.scss';

import { FeaturedProducts, FeaturedArticles, TableOfContents } from './article/index';

const log = logger({ category: 'n4/Article' });

const getReadingTime = (wordCount) => {
  const wordsPerMinute = 400;
  const minutes = wordCount / wordsPerMinute;

  return Math.ceil(minutes);
};

const AboutContentDetails = ({ title, items, text = '' }) => (
  <section className={styles['about-content-details']}>
    <span className={styles['about-content-details-top']}>{title}</span>
    <span className={styles['about-content-details-bottom']}>
      {items?.map((item, index) => (
        <React.Fragment key={item.id}>
          {item.name}
          {index === items.length - 1 ? null : ', '}
        </React.Fragment>
      )) || text}
    </span>
  </section>
);

const AuthorDetails = ({ title, items, text = '' }) => {
  const authors = items
    ?.filter((item) => !!item.name)
    .map((item) => (
      <div key={item.id}>
        {item.slug ? (
          <NextLink key={item.id} className={styles['author-link']} href={`/authors/${item.slug}`}>
            {item.name}
          </NextLink>
        ) : (
          <span key={item.id}>{item.name}</span>
        )}
      </div>
    ));
  return (
    <section className={styles['about-content-details']}>
      <span className={styles['about-content-details-top']}>{title}</span>
      <div className={styles['about-content-details-bottom']}>{authors || text}</div>
    </section>
  );
};

const About = ({ blok, story }) => {
  const { authors } = useApiData();
  if (!authors) {
    return null;
  }
  const primary = blok.primary_author instanceof Array ? authors[blok.primary_author[0]] : authors[blok.primary_author];
  const additional = (blok.additional_authors || []).map((a) => authors[a]);
  const other = (blok.author_other && authors[blok.author_other])?.name || 'Staff';
  const reviewers = (blok.expert_reviewers || []).map((a) => authors[a]);
  return (
    <section className={`${styles.about} mb-8 mt-8`}>
      <section className={`${styles['about-content']} flex w-full flex-wrap items-start justify-between`}>
        <div className="flex w-full flex-wrap items-start pt-4 md:space-x-8 md:pt-0 lg:w-auto">
          {primary ? <AuthorDetails title="Written by" items={[primary, ...additional]} /> : <AuthorDetails title="Written by" text={other} />}
          {reviewers.length ? <AuthorDetails title="Reviewed by" items={reviewers} /> : null}
          {blok.updated ? (
            <AboutContentDetails title="Updated on" text={formatDate(blok.updated)} />
          ) : (
            <AboutContentDetails title="Published on" text={formatDate(blok.published || story.published_at || story.created_at)} />
          )}
        </div>
        <div className={`${styles.share} mt-4 flex w-full items-center md:mt-0 md:w-auto`}>
          <div className="flex w-full justify-start md:justify-end">
            <ShareButtons.Primary />
          </div>
        </div>
      </section>
    </section>
  );
};

const words = (blok) => {
  const markdowns = findBloks(blok, 'n4-markdown');
  return markdowns.reduce((sum, item) => sum + item.content.split(' ').length, 0);
};

const Title = ({ blok, story }) => {
  let tag = 'Editorial';
  if (blok?.sponsor) {
    tag = 'Sponsored';
  } else if (story.content.blocks.some((b) => b.component === 'n4-product-config')) {
    tag = 'Product Review';
  }

  return (
    <section className={styles.details}>
      <section className={styles.meta}>
        <div>{tag}</div>
        <span>{getReadingTime(words(story) || 1)} min read</span>
      </section>
      {/* article title is always an h1 for SEO */}
      <h1>{blok.title || story.name}</h1>
      {blok?.subtitle ? <span className="text-[24px] text-navy-80 max-xs:-mt-4 max-xs:text-[1.4rem]">{blok?.subtitle}</span> : null}
    </section>
  );
};

const Image = ({ blok }) => {
  const [hasImageLoaded, setHasImageLoaded] = React.useState(false);
  const imagePosition = blok?.image_position?.replace(';', '');
  const image = blok.hero_image;

  // This assumes image is an asset
  if (!image?.filename) {
    return null;
  }

  return (
    <motion.figure className={styles.image} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      {image ? (
        <ImageWrapper
          image={image}
          imgProps={{ alt: blok.hero_image.alt || blok.title, style: { objectPosition: imagePosition || 'center' } }}
          onLoaded={(source) => {
            log.debug(`in onload for ${image.filename}: ${source}`);
            setHasImageLoaded(true);
          }}
          origin="n4-article-image"
        />
      ) : null}
      {hasImageLoaded ? null : (
        <div className={styles.skeleton}>
          <Skeleton />
        </div>
      )}
    </motion.figure>
  );
};

const expandBloks = (blok) => {
  if (blok instanceof Array) {
    log.silly('array in: %o', blok);
    const arrayBlocks = blok.map((b) => expandBloks(b));
    log.silly('array out: %o', arrayBlocks);
    return arrayBlocks;
  }
  if (blok.component === 'n4-nested-blocks') {
    log.silly('nested in %o', blok);
    const nestedBlocks = expandBloks(blok.blocks || []);
    log.silly('nested out: %o', nestedBlocks);
    return nestedBlocks;
  }
  log.silly('single in: %o, out: %o', blok, blok);
  return blok;
};

const Content = ({ blok, story, ...props }) => {
  log.debug('Article content');
  log.debug('original blok: %o', blok);
  log.silly('bloks before: %o', blok.body);
  const nested = expandBloks(blok.body || []);
  log.debug('bloks after expanding: %o', nested);
  const bloks = nested.flat(10);
  log.debug('bloks flattend: %o', bloks);

  return (
    <motion.section className={styles.content} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <section className={styles.article}>
        {bloks.map((articleBlok) => {
          if (articleBlok.component === 'n4-markdown') {
            return (
              <section key={articleBlok._uid} className={cx(styles['article-container'], styles['article-container-markdown'], 'mkdn')}>
                <StoryblokComponent key={articleBlok._uid} blok={articleBlok} story={story} {...props} {...storyblokEditable(articleBlok)} />
              </section>
            );
          }
          return <StoryblokComponent key={articleBlok._uid} blok={articleBlok} story={story} {...props} {...storyblokEditable(articleBlok)} />;
        })}
      </section>
    </motion.section>
  );
};

export const Author = ({ author }) => {
  const imageSource = author?.avatar_url?.includes('https:') ? author.avatar_url : `${'https:'}${author.avatar_url}`;

  return (
    <>
      <section className={styles['author-top']}>
        <section className={styles['author-content']}>
          <NextLink href={`/authors/${author.slug}`}>
            <section className={styles['author-intro']}>
              <Avatar.Root>
                <Avatar.Image src={imageSource} />
                <Avatar.Fallback />
              </Avatar.Root>
              <section className={styles.intro}>
                <h4>{author?.name}</h4>
                <span>{author?.about_brief}</span>
              </section>
            </section>
          </NextLink>
        </section>
        <section className={styles['author-connect']}>{author?.social_links ? <ShareButtons.Primary links={author.social_links} /> : null}</section>
      </section>
      {author?.about_markdown && (
        <section className={cx(styles['author-description'], markdownStyles['markdown--author-bio'])}>
          <ReactMarkdown>{author.about_markdown}</ReactMarkdown>
        </section>
      )}
    </>
  );
};

const Authors = ({ blok }) => {
  const { authors } = useApiData();
  if (!authors) {
    return null;
  }
  const allIds = [];
  if (blok.primary_author instanceof Array) {
    allIds.push(...blok.primary_author);
  } else {
    allIds.push(blok.primary_author);
  }
  if (blok.additional_authors?.length) {
    allIds.push(...blok.additional_authors);
  }
  if (blok.author_other) {
    allIds.push(blok.author_other);
  }
  /*
  if (blok.expert_reviewers) {
    allIds.push(...blok.expert_reviewers);
  }
  */
  const all = allIds.map((id) => authors[id]).filter((a) => !!a);
  if (!all.length) {
    return <div className="mb-10" />;
  }
  return (
    <>
      <Divider />
      <section className={styles.authors}>
        <span className={styles['authors-intro']}>Learn more about the author{all.length === 1 ? '' : 's'}</span>
        <ul className={styles['authors-list']}>
          {all.map((author) => (
            <li key={author.id} className={styles.author}>
              <Author author={author} />
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

const Tags = ({ story, title }) => {
  const rejectedTags = ['product page'];

  return (
    <section className={styles['tags-container']}>
      <section className={styles.share}>
        <span>Share this post</span>
        <ShareButtons.Primary title={title} />
      </section>
      {story?.tag_list.length ? (
        <ul className={styles.tags}>
          {story.tag_list
            .filter((tag) => !rejectedTags.includes(tag) && !tag.startsWith('Hub:'))
            .map((tag) => (
              <li key={tag}>{tag}</li>
            ))}
        </ul>
      ) : null}
    </section>
  );
};

const Divider = ({ shouldBeSpaceless }) => <div className={cx(styles.divider, shouldBeSpaceless && styles['divider--spaceless'])} />;

const AffiliateDisclosure = () => (
  <section className={cx(styles['affiliate-disclosure'])}>
    Disclaimer: HearingTracker may earn a commission from this link. Please see our <NextLink href="/ethics-statement">ethics statement</NextLink>.
  </section>
);

// Note; should probably do the api and effect one level up and pass down articles
// If there are article, we show them, else if there are products, show them, else
// if there is a toc, show, else no sidebar and full width
const Article = ({ blok, story, ...props }) => {
  const api = useApiData();
  const [sidebar, setSidebar] = React.useState(api.releasePage ? {} : api.sidebar || {});

  React.useEffect(() => {
    if (api.releasePage) {
      return;
    }
    const fn = async () => {
      const results = await sbSidebar({ story, api, log });
      setSidebar(results || {});
    };
    fn();
  }, [story, api]);

  // Note: only one of these will be populated per the page-config and other rules
  const { articles, products, toc } = sidebar;
  const isProductPage = story.content.blocks.some((b) => b.component === 'n4-product-config');

  log.debug('blok: %o', blok);
  return (
    <section className={cx(styles['n4-article'], blok.className, blok.component)} {...storyblokEditable(blok)} id="article">
      {!props.hideBreadcrumbs && (
        <div className="mb-[2rem] max-w-[100%]">
          <Breadcrumbs data={props.breadcrumbs} />
        </div>
      )}
      {blok.top_divider && <Divider shouldBeSpaceless />}
      <div className="max-w-[916px]">
        <Title blok={blok} story={story} />
      </div>
      {blok.affiliate_disclosure && <AffiliateDisclosure />}
      <About blok={blok} story={story} />
      <div className={`content-container self-stretch lg:flex lg:justify-between ${isProductPage ? 'xs:mt-8' : ''}`}>
        <div className={`main-content show-sidebar:basis-[916px] ${isProductPage ? 'w-full laptop:max-w-[640px]' : ''}`}>
          {blok.hero_image?.filename && (
            <div className={styles['article-info-image']}>
              <Image blok={blok} />
            </div>
          )}
          <Content blok={blok} story={story} {...props} />
          <section className={styles['article-container']}>
            <Tags story={story} title={props.title} />
            <Authors blok={blok} />
          </section>
        </div>
        {(articles || products || toc) && (
          <aside className="sidebar max-w-[400px] flex-1 hide-sidebar:hidden">
            <div className="sticky top-[90px] flex flex-col gap-12">
              {articles && <FeaturedArticles articles={articles} />}
              {products && <FeaturedProducts products={products} />}
              {toc && <TableOfContents toc={toc} />}
            </div>
          </aside>
        )}
      </div>
    </section>
  );
};

export default Article;
