import React from 'react';

import HtLink from 'components/common-n4/ht-link';
import SvgIcon from 'components/ui/svg_icon';

import styles from './breadcrumbs.module.scss';

const getBreadCrumbs = (data) =>
  data
    ?.map(({ label, path }) => {
      if (label) {
        return { title: label, path: label === 'Home' ? path : `${path[0] === '/' ? '' : '/'}${path}` };
      }
      // If we get here, we are doing it wrong.
      return null;
    })
    .filter((breadcrumbs) => breadcrumbs);

export const Breadcrumbs = ({ data }) => {
  const breadcrumbs = getBreadCrumbs(data);
  return (
    <section className={styles.breadcrumbs}>
      <ol className={styles.breadcrumb}>
        {breadcrumbs?.map(({ title, path }, index) => (
          <li className={styles['breadcrumb-item']} key={`${title}-${path}`}>
            <HtLink href={path}>{title}</HtLink>
            {index !== breadcrumbs.length - 1 ? <SvgIcon icon="chevron-right-solid" /> : null}
          </li>
        ))}
      </ol>
    </section>
  );
};
