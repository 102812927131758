import { fetchApi } from 'lib/ht_api';

import type { ApiError, Brand, Entity, Fallback, Logger, ReleaseLite } from './types';

export interface BrandReleases extends Entity {
  brand: Brand;
  releases: Record<string, ReleaseLite[]>;
}

export type BrandReleaseResult = Record<number, BrandReleases>;

interface BrandReleasesParams {
  brandIds: number[];
  origin: string;
  fallback: Fallback;
  log: Logger;
}

type FetchBrandReleases = (params: BrandReleasesParams) => Promise<BrandReleaseResult | Fallback | ApiError>;

export const fetchBrandReleases: FetchBrandReleases = async ({ brandIds, origin, fallback, log }) => {
  const result = await fetchApi({ path: 'brands/releases', variables: { ids: brandIds }, origin, fallback, log });
  return result;
};
