import React from 'react';

import NextImage from 'next/image';

import type { ImageProps, ImageSizingProps } from './types';
import useImageLoader from './use-image-loader';

const LocalImage = (props: ImageProps) => {
  const ref = React.createRef<HTMLImageElement>();
  const { image: filename, imgProps = {}, sizingProps = {}, origin, onLoaded } = props;
  const { minWidth, nativeWidth, nativeHeight, sizes } = sizingProps as ImageSizingProps;
  const { alt } = imgProps;
  const onLoad = useImageLoader({ ref, origin, onLoaded });

  const width = nativeWidth || minWidth;
  const height = !nativeHeight || nativeHeight === 'auto' ? undefined : Number(nativeHeight);

  return (
    <NextImage
      {...imgProps}
      ref={ref}
      src={`/images/${filename}`}
      style={{ objectFit: 'contain', ...imgProps.style }}
      sizes={sizes}
      fill={width ? undefined : true}
      width={width}
      height={height}
      onLoad={onLoad}
      data-origin={`local-${origin}`}
      data-attributes={process.env.NODE_ENV === 'production' ? '' : JSON.stringify(props)}
      loading={imgProps.loading}
      alt={alt || 'image'}
    />
  );
};

export default LocalImage;
