import dayjs from 'dayjs';
import NextLink from 'next/link';

// eslint-disable-next-line import/order
const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const friendlyTime = (dj) => dj.format('D MMMM YYYY');
const iso8601 = (dj) => dj.utc().format("YYYY-MM-DDTHH:mm:ss.SSS'+00:00'");

const AuthorTop = ({ story, titleBlok }) => {
  if (!story.authors?.length) {
    return null;
  }
  const [author, ...additionalAuthors] = story.authors;
  const medicalReviewers = story.medicalReviewers || [];
  const publishedAt = dayjs(titleBlok.published_on);
  const modifiedAt = dayjs(titleBlok.updated_on || titleBlok.published_on);
  return (
    <>
      <div className="d-md-flex" style={{ fontSize: 14, marginBottom: '.8rem' }}>
        <div>
          By&nbsp;
          <strong>
            <NextLink href={`/authors/${author.slug}`} className="link-unstyled" style={{ borderBottom: '2px solid' }}>
              {author.name}
            </NextLink>
          </strong>
          {additionalAuthors.map((a, idx) => (
            <span key={a.id}>
              {additionalAuthors.length === 1 && <span> and </span>}
              {additionalAuthors.length > 1 && idx === additionalAuthors.length - 1 && <span>, and </span>}
              {additionalAuthors.length > 1 && idx !== additionalAuthors.length - 1 && <span>, </span>}
              <strong>
                <NextLink href={`/authors/${a.slug}`} className="link-unstyled" style={{ borderBottom: '2px solid' }}>
                  {a.name}
                </NextLink>
              </strong>
            </span>
          ))}
        </div>

        <div className="pub-date mt-md-0 mt-1">
          {modifiedAt && modifiedAt.isAfter(publishedAt) ? (
            <time dateTime={iso8601(modifiedAt)}>Updated {friendlyTime(modifiedAt)}</time>
          ) : (
            <time dateTime={iso8601(publishedAt)}>Published {friendlyTime(publishedAt)}</time>
          )}
        </div>
      </div>
      {medicalReviewers.length > 0 && (
        <div style={{ fontSize: 14, marginBottom: '8.rem' }}>
          Medically reviewed by
          <strong>
            {medicalReviewers.map((a) => (
              <NextLink key={a.id} href={`/authors/${a.slug}`} className="link-unstyled" style={{ borderBottom: '2px solid' }} />
            ))}
          </strong>
        </div>
      )}
    </>
  );
};

export default AuthorTop;
