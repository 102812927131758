import React from 'react';

import { Button } from 'components/common-n4/button';
import { Link } from 'components/common-n4/link';
import { useOncePerPage } from 'hooks';
import Logger from 'lib/logger';
import { normalizeUrl } from 'lib/utils';
import type { Release, Model, HearingAid, Price } from 'types/release';

import Currency from './currency';
import { ProviderFlow, CTASegments, recordAB, getCtaText } from './provider-pop-up';
import { Event } from './provider-pop-up/types';

const log = Logger({ category: 'price-link' });

interface PriceLinkProps {
  release: Release;
  model?: Model;
  hearingAid?: HearingAid;
  price?: Price;
  className?: string;
  onModalOpenChange?: (isOpening: boolean) => void;
  origin: string;
}

const segmentOrigin = 'link';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PriceLink: React.FC<PriceLinkProps> = ({ release, model, hearingAid, price, className, onModalOpenChange, origin }) => {
  const [segment, setSegment] = React.useState(-2);
  const [isProviderShowing, showProvider] = React.useState<boolean>(false);
  const useOnce = useOncePerPage();

  log.debug('release: %s, price: %o', release?.slug, price);

  if (useOnce(`price-link-${segment}`)) {
    if (segment >= 0) {
      recordAB(Event.ButtonViewed, segment, segmentOrigin);
    }
  }

  React.useEffect(() => {
    if (typeof window.localStorage !== 'undefined') {
      let seg: number | null = null;
      if (localStorage.getItem('pfl-segment')) {
        seg = parseInt(localStorage.getItem('pfl-segment')!, 10);
        if (seg < 0 || seg > CTASegments[segmentOrigin].length - 1) {
          seg = null;
        }
      }
      if (seg === null) {
        seg = Math.floor(Math.random() * CTASegments[segmentOrigin].length);
        window.localStorage.setItem('pfl-segment', seg.toString());
      }
      setSegment(seg);
    } else {
      setSegment(-1);
    }
  }, []);

  const handleSearch = () => {
    showProvider(true);
    if (onModalOpenChange) {
      onModalOpenChange(true);
    }
  };

  const onClose = () => {
    showProvider(false);
    if (onModalOpenChange) {
      onModalOpenChange(false);
    }
  };

  const prefix = 'From ',
    suffix = ' a pair';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let cta = '';
  if (segment >= 0) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cta = getCtaText(release.name, segment, segmentOrigin);
  }
  if (price?.provider_search) {
    // display popup
    return (
      <>
        <ProviderFlow
          release={release}
          model={model}
          hearingAid={hearingAid}
          onClose={onClose}
          isModalOpen={isProviderShowing}
          origin={origin}
          segment={segment}
          segmentOrigin={segmentOrigin}
        />
        <Button.LinkButton className="!justify-normal !gap-[0.2rem] !p-0" onClick={handleSearch}>
          <span key="prefix">{prefix}</span>
          <Currency key="currency" price={price.purchase_price} fallback={'N/A'} />
          <span key="suffix">{suffix} with local service</span>
        </Button.LinkButton>
      </>
    );
  }

  if (price?.url) {
    return (
      <Link
        variant="primary"
        className="!justify-normal !gap-[0.2rem] !p-0"
        href={normalizeUrl({ url: price.url, origin: `price-button[${origin}]#1` })}
        shouldOpenNewTab
      >
        {prefix}
        <Currency price={price.purchase_price} fallback={'N/A'} />
        {suffix}
      </Link>
    );
  }

  if (price) {
    // TODO: link to product page on these unless we're already there
    return (
      <span className="!justify-normal !gap-[0.2rem] !p-0">
        {prefix}
        <Currency price={price.purchase_price} fallback={'N/A'} />
        {suffix}
      </span>
    );
  }

  return <span>Price Not Available</span>;
};

export default PriceLink;
