import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'FeaturedArticles' });

const FeaturedArticles = ({ blok, featuredArticles }) => {
  const imageTemplate = blok.imageTemplate ? blok.imageTemplate[0] : {};
  return (
    <section {...storyblokEditable(blok)} className="ht-featured-articles">
      <div className="ht-featured-articles-wrapper">
        <header className="ht-featured-articles-section_header">
          <h2>{blok.title}</h2>
          <p className="h3">{blok.subtitle}</p>
        </header>
        <article>
          <div className="ht-featured-articles-list">
            {(featuredArticles || []).map((article) => (
              <div key={article.id} className="ht-featured-articles-list-item">
                <NextLink className="article-link" href={article.link} rel={article.noindex ? 'nofollow' : ''}>
                  <article className="ht-featured-articles-card">
                    <figure>
                      <ImageWrapper image={imageTemplate || article.image} imgProps={{ alt: article.title }} origin="featured-articles" />
                    </figure>
                    <div className="ht-featured-articles-card-body">
                      <header>
                        <div className="article-badge">News Article</div>
                        <h3>{article.title}</h3>
                        {/* {!!article.tags && (
                        <ul className="article-tags">
                          {article.tags.map((tag) => (
                            <li key={tag.text}>
                              <NextLink href={tag.link} rel="nofollow">{tag.text}</NextLink>
                            </li>
                          ))}
                        </ul>
                        )} */}
                      </header>
                      <div className="content">
                        <p className="article-published">{article.published_at}</p>
                        <p className="article-description">{article.description_truncated}</p>
                      </div>
                      <footer className="footer d-none">{/* <NextLink href={article.link} rel="nofollow">Continue Reading</NextLink> */}</footer>
                    </div>
                  </article>
                </NextLink>
              </div>
            ))}
          </div>

          <div className="ht-featured-articles-view_all_btn">
            <NextLink href="/news">View all articles</NextLink>
          </div>
        </article>
      </div>
    </section>
  );
};

export default FeaturedArticles;
