import React from 'react';

import { useRouter } from 'next/router';

import logger from 'lib/logger';

const log = logger({ category: 'once-per-page' });

const OncePerPageContext = React.createContext();

const useOncePerPage = () => React.useContext(OncePerPageContext);

if (typeof window !== 'undefined') {
  window.ht_once_per_page = {};
}

const OncePerPageContextProvider = ({ children }) => {
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteChange = (url) => {
      if (typeof window !== 'undefined') {
        log.debug('resetting used: %s', url);
        window.ht_once_per_page = {};
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      if (typeof window !== 'undefined') {
        window.ht_once_per_page = {};
      }
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const useOnce = React.useCallback((item) => {
    if (typeof window !== 'undefined') {
      log.debug('used: %o', window.ht_once_per_page);
      if (window.ht_once_per_page[item]) {
        log.debug('%s already used, returning false', item);
        return false;
      }
      log.debug('%s marking used, returning true', item);
      window.ht_once_per_page[item] = true;
      return true;
    }
    return false;
  }, []);

  return <OncePerPageContext.Provider value={useOnce}>{children}</OncePerPageContext.Provider>;
};

export { OncePerPageContext, useOncePerPage, OncePerPageContextProvider };
