import React, { useMemo } from 'react';

import { storyblokEditable } from '@storyblok/react';
import ReactCarousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { ArticleFeatureCard } from 'components/common-n4/article-feature-card/article-feature-card';
import { Heading } from 'components/common-n4/heading';
import ImageWrapper from 'components/common-n4/image';
import { Link } from 'components/common-n4/link';
import fonts from 'components/layout/fonts';
import { useApiData } from 'hooks/use_api_data';
import { useIsMobileView } from 'hooks/use_is_mobile_view';
import { useWindowDimensions, WINDOW_DIMENSION_TYPE } from 'hooks/use_window_dimensions';
import { cx, getWindowDimensionType, normalizeUrl } from 'lib/utils';

import styles from './hero-reviews.module.scss';

import HomeArticle from './article';
import HomeHearingAid from './hearing-aid';

const origin = 'bloks/n4/home/hero-reviews/index.js';

const Logos = ({ logoText, logoList }) => {
  const { width } = useWindowDimensions(true);
  const dimensionType = getWindowDimensionType(width);

  const itemCount = useMemo(() => {
    const logoConfig = {
      mobile: { width: 50, gap: 64 },
      desktop: { width: 70, gap: 115 },
      wide: { width: 80, gap: 168 },
    };
    switch (dimensionType) {
      case WINDOW_DIMENSION_TYPE.mobile:
        return width / (logoConfig.mobile.width + logoConfig.mobile.gap);
      case WINDOW_DIMENSION_TYPE.wide:
        return width / (logoConfig.wide.width + logoConfig.wide.gap);
      default:
        return width / (logoConfig.desktop.width + logoConfig.desktop.gap);
    }
  }, [dimensionType, width]);

  return (
    <div className={styles.logos}>
      <div className={styles.logoText}>{logoText}</div>
      <ReactCarousel
        autoPlay
        infinite
        pauseOnHover
        shouldResetAutoplay
        autoPlaySpeed={5}
        additionalTransfrom={0}
        transitionDuration={5000}
        arrows={false}
        rtl={false}
        rewind={false}
        showDots={false}
        rewindWithAnimation={false}
        customTransition="all 5s linear"
        responsive={{
          all_Devices: {
            breakpoint: { max: Infinity, min: 0 },
            items: itemCount,
          },
        }}
        containerClass={styles.logoContainer}
        sliderClass={cx(logoList.length <= itemCount && styles[`logo-slider--fit`])}
        itemClass={cx(styles.sliderItem, logoList.length <= itemCount && styles[`logo-slider-item--fit`])}
      >
        {logoList.map((logo) => (
          <div key={logo.id} className={styles.logoImage}>
            <ImageWrapper image={logo} imgProps={{ style: { width: '100%', height: '100%' } }} origin={origin} />
          </div>
        ))}
      </ReactCarousel>
    </div>
  );
};

const HeroReviews = ({ blok, className, ...props }) => {
  const { models } = useApiData();
  const { articles, reviews } = blok;
  const [primaryArticle, ...secondaryArticles] = articles;
  const [primaryReview, ...secondaryReviews] = reviews;
  const primaryModel = models[primaryReview.form_factor];
  const isMobileView = useIsMobileView();
  const logoList = blok.logos;

  return (
    <section className={cx(styles['hero-reviews'], className, blok.className)} {...storyblokEditable(blok)}>
      <div className={styles.headingContainer}>
        <Heading variant="h1" className={styles.heading}>
          {blok.headline_white} <em>{blok.headline_blue}</em>
        </Heading>
      </div>
      <section>
        <div className={styles.container}>
          <div className={styles.containerContent}>
            <div className={styles['column-header-container']}>
              <Heading variant="h5" className={cx(fonts.hankenGrotesk.className, styles['column-header'])}>
                {blok.article_title}
              </Heading>
              <Link href="/hearing-aids/best">
                <span className={cx(fonts.hankenGrotesk.className, styles['column-header-link'])}>view all</span>
              </Link>
            </div>
            <ArticleFeatureCard
              className={styles.articleFeatureCard}
              extended
              image={primaryArticle.image}
              title={primaryArticle.title}
              body={primaryArticle.description}
              url={primaryArticle.link.cached_url}
              {...storyblokEditable(primaryArticle)}
            />
            {isMobileView && (
              <div className={styles.column}>
                {secondaryArticles?.map((c) => (
                  <HomeArticle key={c._uid} blok={c} {...props} {...storyblokEditable(c)} />
                ))}
              </div>
            )}
          </div>
          <div className={styles.containerContent}>
            <div className={styles['column-header-container']}>
              <Heading variant="h5" className={cx(fonts.hankenGrotesk.className, styles['column-header'])}>
                {blok.review_title}
              </Heading>
              <Link href="/hearing-aids/compare">
                <span className={cx(fonts.hankenGrotesk.className, styles['column-header-link'])}>view all</span>
              </Link>
            </div>
            <ArticleFeatureCard
              className={styles.articleFeatureCard}
              extended
              image={primaryModel.release.transparent_image || primaryModel.release.image}
              title={primaryModel.release.name}
              body={primaryModel.release.description}
              url={normalizeUrl({ url: primaryModel.release.path, origin })}
              score={{ amount: primaryModel.score, progress: (primaryModel.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100 }}
              {...storyblokEditable(primaryReview)}
            />
            {isMobileView && (
              <div className={styles.column}>
                {secondaryReviews?.map((c) => (
                  <HomeHearingAid key={c._uid} blok={c} {...props} {...storyblokEditable(c)} />
                ))}
              </div>
            )}
          </div>
        </div>
        {!isMobileView && (
          <div className={styles.container}>
            <div className={styles.column}>
              {secondaryArticles?.map((c) => (
                <HomeArticle key={c._uid} blok={c} {...props} {...storyblokEditable(c)} />
              ))}
            </div>
            <div className={styles.column}>
              {secondaryReviews?.map((c) => (
                <HomeHearingAid key={c._uid} blok={c} {...props} {...storyblokEditable(c)} />
              ))}
            </div>
          </div>
        )}
      </section>
      <Logos logoText={blok.logo_text} logoList={logoList} />
    </section>
  );
};

export default HeroReviews;
