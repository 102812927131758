import { storyblokEditable } from '@storyblok/react';

import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';
import { cx, normalizeUrl } from 'lib/utils';

import styles from './image.module.scss';

const log = logger({ category: 'hero' });

const N4Image = ({ blok }) => {
  log.debug('blok: %o', blok);

  let data = {};
  if (blok.dataset) {
    data = blok.dataset.split(',').reduce((h, d) => Object.assign(h, { [`data-${d.split('=')[0]}`]: d.split('=')[1] }), {});
  }

  const image = (
    <figure className={styles.image}>
      <ImageWrapper
        image={blok.image || blok.filename}
        imgProps={{
          alt: blok.image?.alt || blok.caption || blok.alt_text,
          title: blok.title,
          loading: 'eager',
          style: { width: 'auto', height: '100%', borderRadius: '1.25rem' },
        }}
        origin="n4-image"
      />
    </figure>
  );

  return (
    <section className={cx(styles['n4-image'], styles[blok.classname], 'n4-image')} {...storyblokEditable(blok)}>
      {!blok.hyperlink?.cached_url ? (
        image
      ) : (
        <HtLink href={normalizeUrl({ url: blok.hyperlink.cached_url, origin: 'blok/image' })} rel="noopener noreferrer nofollow" target="_blank" {...data}>
          {image}
        </HtLink>
      )}
      {blok.caption && (
        <section className={styles.caption}>
          <span>{blok.caption}</span>
        </section>
      )}
    </section>
  );
};

export default N4Image;
