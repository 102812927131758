import React, { useState } from 'react';

import { storyblokEditable, type SbBlokData } from '@storyblok/react';

import SvgIcon from 'components/ui/svg_icon';
import CheckedIcon from 'images/checked.svg';
import IconArrowRight from 'images/icon-arrow-right.svg';
import EmailIcon from 'images/icon-email.svg';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import { validateEmail, tw } from 'lib/utils';

import styles from './contact.module.scss';

import ContactFAQ, { FAQData } from './contact-faq';

const log = logger({ category: 'contact' });

export interface Blok extends SbBlokData {
  faqData: FAQData;
}

interface ContactFormProps {
  blok: Blok;
}

const Contact: React.FC<ContactFormProps> = ({ blok }) => {
  log.debug('blok: %o', blok);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [email, setEmail] = useState({ address: '', error: false });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail({
      address: value,
      error: !validateEmail(value),
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const variables = Object.fromEntries(formData.entries());
    variables.subscribe = (document.getElementById('contact-form-subscribe') as HTMLFormElement)?.checked;

    // eslint-disable-next-line no-console
    log.debug('Form Data:', variables);

    if (!email.error && email.address) {
      const result = await fetchApi({ path: 'nav/contact', method: 'POST', variables, fallback: 'friendly', origin: 'contact-form', log });
      setSubmitMessage(result.message);
      setFormSubmitSuccess(!result.error);
    } else {
      setEmail({ address: email.address, error: true });
    }
  };

  return (
    <>
      <section
        className={tw(styles.contact, 'flex w-full justify-center p-[32px_0_80px] md:p-[64px_64px_32px_64px] desktop-xl:m-auto desktop-xl:max-w-[1440px]')}
        {...storyblokEditable(blok)}
      >
        <div className="flex w-full flex-col gap-[40px] laptop:flex-row laptop:gap-[80px]">
          <div className="flex-1">
            <div className="flex flex-col gap-5 md:gap-6">
              <div
                className="text-left text-[40px] font-semibold leading-[48px] tracking-[-1.2px] 
              text-navy md:text-[56px] md:leading-[67.2px] md:tracking-[-1.68px]"
              >
                Connect with us
              </div>
              <div
                className="text-left text-[16px] font-normal leading-[20.8px] tracking-[-0.48px] 
              text-navy/90 md:text-[18px] md:leading-[25.2px] md:tracking-[-0.54px]"
              >
                Our friendly team would love to hear from you.
              </div>
            </div>
            <div className="mt-8 flex items-center gap-4 py-2">
              <div className="flex h-6 w-6 items-center justify-center">
                <EmailIcon />
              </div>
              <div className="flex flex-col">
                <span className="text-gray-95 text-[12px] font-medium leading-[15.6px] tracking-[-0.36px]">Email Address</span>
                <a href="mailto:info@hearingtracker.com" className="text-[16px] font-normal leading-[20.8px] tracking-[-0.48px] text-navy underline">
                  info@hearingtracker.com
                </a>
              </div>
            </div>
          </div>

          <div className="flex-1">
            {!formSubmitSuccess ? (
              <>
                <div className="w-full lg:w-[668px] lg:max-w-[668px]">
                  <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
                    <div
                      className="flex w-full flex-col gap-6 rounded-[1.25rem] bg-white p-[2rem_2rem_2.5rem] 
                      shadow-xs md:p-[2.5rem]"
                    >
                      {submitMessage && <div style={{ color: '#c00' }}>{submitMessage}</div>}
                      <div className="flex flex-col gap-6 md:flex-row">
                        <div className="flex w-full flex-col gap-3 md:gap-2">
                          <label htmlFor="firstName" className="block text-[16px] font-medium leading-[22.4px] tracking-[-0.48px] text-navy md:leading-[24px]">
                            First name
                          </label>
                          <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            placeholder="Your Name"
                            className="w-full rounded-[6px] border border-navy/10 px-4 py-3 text-navy focus:outline-none md:rounded-[12px]"
                          />
                        </div>
                        <div className="flex w-full flex-col gap-3 md:gap-2">
                          <label
                            htmlFor="lastName"
                            className="block text-[16px] font-medium leading-[24px] text-navy  md:leading-[22.4px] md:tracking-[-0.03px]"
                          >
                            Last name
                          </label>
                          <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            placeholder="Your Name"
                            className="w-full rounded-[6px] border border-navy/10 px-4 py-3 focus:outline-none md:rounded-[12px]"
                          />
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-3 md:gap-2">
                        <label
                          htmlFor="email"
                          className="block text-[16px] font-medium leading-[24px] 
                        text-navy md:leading-[22.4px] md:tracking-[-0.03px]"
                        >
                          Email
                        </label>
                        <input
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          value={email.address}
                          onChange={handleEmailChange}
                          className={`w-full border px-4 py-3 ${email.error ? 'border-[2px] border-error' : 'border-navy/10'} 
                          rounded-[6px] focus:outline-none md:rounded-[12px]`}
                        />
                      </div>

                      <div className="flex w-full flex-col gap-3 md:gap-2">
                        <label
                          htmlFor="message"
                          className="block text-[16px] font-medium leading-[24px] text-navy 
                        md:leading-[22.4px] md:tracking-[-0.03px]"
                        >
                          Message
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          placeholder="Type your message..."
                          className="min-h-[180px] w-full rounded-[6px] border border-navy/10 p-4 
                          focus:outline-none md:rounded-[12px]"
                        />
                      </div>
                      {/* eslint-disable jsx-a11y/label-has-associated-control */}
                      <label className="relative flex cursor-pointer gap-3">
                        <div className="relative h-6 w-6">
                          <input
                            type="checkbox"
                            id="contact-form-subscribe"
                            className="peer h-6 w-6 appearance-none rounded-md border-2 border-navy/20 
                            checked:border-none checked:bg-lapis"
                          />
                          <span
                            className="absolute inset-0 flex items-center justify-center 
                            text-sm font-bold opacity-0 peer-checked:opacity-100"
                          >
                            <SvgIcon icon="check-solid" className="invert" />
                          </span>
                        </div>
                        <span className="text-[16px] font-normal leading-[20.8px] tracking-[-0.48px] text-navy">
                          I want to subscribe to HearingTracker’s newsletter to stay updated on the latest news, expert reviews, and insights.
                        </span>
                      </label>
                    </div>
                    <button type="submit" className="mt-[1rem] flex w-full items-center justify-end gap-4 lg:max-w-[668px]">
                      <span className="text-[18px] font-medium leading-[21.6px] tracking-[-0.54px] text-navy">Submit</span>
                      <div className={tw('w-fit rounded-full bg-navy p-[17px]', styles.arrow_icon)}>
                        <IconArrowRight />
                      </div>
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <div
                className="flex min-h-[420px] w-full flex-1 flex-col items-center justify-center 
              rounded-[40px] bg-white p-8 pb-12 md:min-h-[720px] lg:w-[668px] lg:max-w-[668px]"
              >
                <div className="flex flex-col items-center justify-center gap-4">
                  <CheckedIcon />
                  <div className="flex flex-col gap-2">
                    <div
                      className="text-center text-[24px] font-semibold leading-[31.2px] 
                    tracking-[-0.72px] text-navy"
                    >
                      Success!
                    </div>
                    <div
                      className="max-w-[290px] text-center text-[16px] font-medium leading-[22.4px] 
                    tracking-[-0.48px] text-navy/80"
                    >
                      A member of our team will be in touch with you shortly.
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {blok.faqData && <ContactFAQ faqData={blok.faqData} />}
    </>
  );
};

export default Contact;
