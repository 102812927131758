import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import ImageWrapper from 'components/common-n4/image';
import SvgIcon from 'components/ui/svg_icon';
import logger from 'lib/logger';
import { cx, normalizeUrl } from 'lib/utils';

import styles from './call_to_action.module.scss';

const log = logger({ category: 'n4/CallToAction' });

// TODO: remove this once we verify we've updated all CTA bloks
const legacyAnalytics = { category: 'paid-marketing', label: 'sennheiser' };

const origin = 'cms/call-to_action';

const CallToAction = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  const { image } = blok;
  const isHomepage = props.title === 'Home';
  const url = blok?.button_link?.cached_url;

  log.debug('url: %o', url);

  if (!url) {
    log.warn('No url, returning');
    return <div>No url: {JSON.stringify(blok)}</div>;
  }

  return (
    <section
      className={cx(
        blok.className,
        blok.component,
        isHomepage ? `${styles['home-call-to-action']}` : 'mx-auto max-w-[1344px]',
        styles['n4-cta'],
        styles[`cta-${blok.card_position || 'left'}`]
      )}
      {...storyblokEditable(blok)}
    >
      <figure className={styles.image}>
        <ImageWrapper
          image={image}
          imgProps={{
            alt: image.alt || blok.title,
            style: { width: '100%', height: '100%', objectPosition: blok?.image_position?.replace(';', '') || 'center' },
          }}
          origin={origin}
        />
      </figure>
      <NextLink
        href={normalizeUrl({ url, origin })}
        data-track
        data-event-name="call-to-action"
        data-event-origin={origin}
        data-analytics-category={blok.analytics_category || legacyAnalytics.category}
        data-analytics-label={blok.analytics_label || legacyAnalytics.label}
        className={cx(styles.content, styles[`content-${blok.card_position || 'left'}`])}
      >
        <span className={styles['top-title']}>{blok.small_text}</span>
        <span className={styles.sponsoredLabel}>Sponsored</span>
        <section className={styles['content-data']}>
          <section className={styles.about}>
            <span className={styles.title}>{blok.title}</span>
            <p className={styles.description}>{blok.description}</p>
          </section>
          <section className={styles.action}>
            <span>{blok.button_text}</span>
            <section className={styles.offer}>
              <SvgIcon icon="arrow-right-regular" />
            </section>
          </section>
        </section>
      </NextLink>
    </section>
  );
};

export default CallToAction;
