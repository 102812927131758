import React, { useEffect } from 'react';

import { storyblokEditable } from '@storyblok/react';
import { motion, useScroll, useTransform } from 'framer-motion';

import { Background } from 'components/bloks/n4/home/hero/background/index';
import { Bottom } from 'components/bloks/n4/home/hero/bottom/index';
import fonts from 'components/layout/fonts';
import { useWindowDimensions } from 'hooks/use_window_dimensions';
import { cx } from 'lib/utils';

import styles from './index.module.scss';

import { Buttons } from './buttons';

const Hero = ({ blok }) => {
  const { height } = useWindowDimensions(true);
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, height], [1, 0.2]);

  useEffect(() => {
    const mobileViewPortHeight = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--mobileHeight', `${mobileViewPortHeight}px`);
  }, []);

  return (
    <section className={cx(styles.hero, blok.className, fonts.hankenGrotesk.className)} {...storyblokEditable(blok)}>
      <div className={styles.outer}>
        <Background />
        <div className={styles.container}>
          <div className={styles.top}>
            <div className={styles.space1} />
            <div className={styles['top-content']}>
              <motion.h2 initial={{ opacity: 1 }} style={{ opacity }} className={styles.title}>
                {blok.title}
              </motion.h2>
              <motion.div initial={{ opacity: 1 }} style={{ opacity, zIndex: 3 }} className={styles.buttons}>
                <Buttons blok={blok} />
              </motion.div>
            </div>
          </div>
          <div>
            <Bottom blok={blok} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
