import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';
import { motion } from 'framer-motion';

import { CircleProgress } from 'components/bloks/n4/images/circle_progress';
import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import SvgIcon from 'components/ui/svg_icon';
import Price from 'components/widgets/price';
import PriceButton from 'components/widgets/price-button';
import { useAudio } from 'hooks';
import { useApiData } from 'hooks/use_api_data';
import { useIsMobileView } from 'hooks/use_is_mobile_view';
import logger from 'lib/logger';
import { cx, formatSecondsToTime, normalizeUrl } from 'lib/utils';

import styles from './product_card.module.scss';

import { SubScore, useSubScore } from './product/expert-review';
import { IdealScenarios, ProductFeatures } from './product/product-scorecard';

const log = logger({ category: 'n4/ProductCard' });

const Audio = ({ audio }) => {
  const audioRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState('0:00');
  const [duration, setDuration] = React.useState('0:00');
  const [progress, setProgress] = React.useState(0);

  const { currentlyPlaying, setCurrentlyPlaying, hasSceneChanged /* setHasSceneChanged */ } = useAudio();

  const updateProgress = React.useCallback(() => {
    if (!audioRef.current) return;
    setCurrentTime(formatSecondsToTime(audioRef.current.currentTime));
    setProgress(audioRef.current.currentTime / audioRef.current.duration);
  }, []);

  const handlePlay = () => {
    if (!audioRef.current) return;

    // if there is any audio playing, pause it.
    currentlyPlaying?.audio?.pause();

    let updatedTime = 0;
    if (hasSceneChanged || (currentlyPlaying?.id === audio?.id && currentTime && currentTime === duration)) {
      audioRef.current.currentTime = 0;
      // setHasSceneChanged(false);
    }
    // cancel progress bar animation of any last existing audio.
    else if (currentlyPlaying?.audio) {
      audioRef.current.currentTime = currentlyPlaying.audio.currentTime;
      updatedTime = currentlyPlaying.audio.currentTime;
    }
    // throttle current time updates to every one second to avoid extra re-renders
    const timeFormatted = formatSecondsToTime(updatedTime);
    setCurrentTime(timeFormatted);

    // play the audio
    audioRef.current.play();
    // local state knows audio is playing
    setIsPlaying(true);
    // context knows audio is playing so another Audio component can cancel.
    setCurrentlyPlaying({
      id: audio.id,
      audio: audioRef.current,
    });
  };

  const handlePause = React.useCallback(() => {
    if (!audioRef.current) return;

    // to avoid calling pause method twice unnecessarily when another audio element calls to pause this method via currentlyPlaying?.pause();
    if (isPlaying) audioRef.current.pause();

    setIsPlaying(false);
  }, [isPlaying]);

  const handleMetadataLoaded = () => {
    if (audioRef.current && audioRef.current.duration) {
      setDuration(formatSecondsToTime(audioRef.current.duration));
    }
  };

  React.useEffect(() => {
    const audioEl = audioRef.current;

    if (audioEl) {
      audioEl.addEventListener('ended', handlePause);
      // to trigger handlePause when another Audio component triggers pause in the DOM to the audio in this component.
      audioEl.addEventListener('pause', handlePause);
      audioEl.addEventListener('timeupdate', updateProgress);
    }

    return () => {
      if (audioEl) {
        audioEl.removeEventListener('ended', handlePause);
        audioEl.removeEventListener('pause', handlePause);
        audioEl.removeEventListener('pause', updateProgress);
      }
    };
  }, [handlePause, updateProgress]);

  if (!audio?.url) return null;

  return (
    <>
      <button type="button" onClick={isPlaying ? handlePause : handlePlay} aria-label={isPlaying ? 'play audio' : 'pause audio'}>
        <figure className={styles['audio-icon-circle']}>
          <CircleProgress size={49} progress={progress * 100} isStatic />
          <figure className={styles['audio-icon-play-or-pause']}>
            <SvgIcon icon={isPlaying ? 'pause' : 'play'} />
          </figure>
        </figure>
      </button>
      <section className={styles['audio-details']}>
        <p>Listen to this device</p>
      </section>
      {/* note: tried preload=metadata and that loads the whole thing which is fine for one, but not for 20 */}
      <audio ref={audioRef} src={audio.url} type="audio/m4a" preload="none" onLoadedMetadata={handleMetadataLoaded} />
    </>
  );
};

const getPriority = (item) => {
  if (item.default) return 0;
  if (!item.tuned) return 1;
  if (item.tuned) return 2;
  return -1;
};

const SCORES_TO_SHOW_INITIALLY = 10;

const sortAudio = (a, b) => getPriority(a) - getPriority(b);

const ProductCard = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });

  const { models } = useApiData();

  const isMobileView = useIsMobileView();
  const model = blok.form_factor ? models[blok.form_factor] : models[`ha-${blok.hearing_aid}`];
  const hearingAid = model?.hearing_aids?.[0];
  // const audios = React.useMemo(() => hearingAid?.audios || [], [hearingAid]);
  const audioData = React.useMemo(
    () => [...(hearingAid?.audios?.filter(({ environment, tuned }) => environment.includes('quiet') && tuned === false) || [])].sort(sortAudio),
    [hearingAid?.audios]
  );
  const productLink = model.release.path
    ? normalizeUrl({ url: model.release.path, origin: `blocks/n4/product-card#${blok.form_factor || blok.hearing_aid}` })
    : null;

  const subScoresWithScores = useSubScore(hearingAid?.scores);
  const [isViewMore, setIsViewMore] = React.useState(false);

  // at least default audio should be there to load
  if (!hearingAid || !audioData) return null;

  const score = model.release_score || model.score;
  const name = blok.form_factor ? model.release.full_name : hearingAid.name;
  const price = blok.form_factor ? model.price : hearingAid.price;

  return (
    <section
      className={cx(styles['n4-product-card'], blok.className, blok.component)}
      {...storyblokEditable(blok)}
      data-form-factor={blok.form_factor || ''}
      data-hearing-aid={blok.hearing_aid || ''}
    >
      <div className={styles.container}>
        <section className={cx(styles.left, 'relative lg:sticky lg:top-[90px] lg:pb-5')}>
          {productLink && (
            <HtLink href={productLink}>
              <figure>
                <ImageWrapper
                  image={model.image}
                  imgProps={{
                    loading: 'eager',
                    alt: hearingAid.name,
                  }}
                  // sizingProps={{
                  //   trim: 'auto',
                  // }}
                  origin="product-card"
                />
              </figure>
              {score ? (
                <CircleScoreFluid
                  size="md"
                  progress={(score / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
                  amount={score}
                  className="absolute left-0 top-0 shadow-[1px_2px_10px_0_rgba(0,0,0,0.15)]"
                />
              ) : null}
            </HtLink>
          )}
          {!productLink && (
            <a href="#" className="pointer-events-none">
              <figure>
                <ImageWrapper
                  image={model.image}
                  imgProps={{
                    loading: 'eager',
                    alt: hearingAid.name,
                  }}
                  // sizingProps={{
                  //   trim: 'auto',
                  // }}
                  origin="product-card"
                />
              </figure>
              {score ? (
                <CircleScoreFluid
                  size="md"
                  progress={(score / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
                  amount={score}
                  className="absolute left-0 top-0 shadow-[1px_2px_10px_0_rgba(0,0,0,0.15)]"
                />
              ) : null}
            </a>
          )}
          {!isMobileView && (
            <PriceButton
              release={model.release}
              model={model}
              hearingAid={blok.form_factor ? undefined : hearingAid}
              price={price}
              origin="cms/product-card/desktop"
              noGeo
            />
          )}
        </section>
        <section className={styles.right}>
          <section className={styles.details}>
            <h4>
              <HtLink href={productLink} className={productLink ? null : 'pointer-events-none'}>
                {name}
              </HtLink>
            </h4>
            <span>
              <Price
                releaseSlug={model.release_slug}
                modelId={model.id}
                hearingAidId={blok.form_factor ? undefined : hearingAid.id}
                price={price}
                noGeo
                prefix={<span className="mr-1">From</span>}
                suffix={<span className="ml-1">a pair</span>}
                loading="Loading price..."
                origin="cms/product-card"
              />
            </span>
            {model.release.description || blok.description ? <p>{model.release.description || blok.description}</p> : <div className="my-2" />}
          </section>
          {audioData.length ? (
            <section className="mb-5 md:mb-7">
              <motion.div
                className={cx(styles.animate)}
                variants={{
                  initial: { opacity: 0 },
                  animate: { opacity: 1, transition: { duration: 0.75 } },
                }}
                initial="initial"
                animate="animate"
              >
                <ul className={styles.audio}>
                  {audioData.map((audio) => (
                    <li key={`${audio.environment}-${!!audio.tuned}-${!!audio.default}`}>
                      <Audio audio={audio} />
                    </li>
                  ))}
                </ul>
              </motion.div>
            </section>
          ) : null}
          {isMobileView && (
            <PriceButton
              release={model.release}
              model={model}
              hearingAid={blok.form_factor ? undefined : hearingAid}
              price={price}
              origin="cms/product-card/mobile"
              noGeo
            />
          )}
          <section className="scores">
            {model.tags.length > 0 && <IdealScenarios tags={model.tags} className="pb-8 pt-7 md:border-t md:border-gray" titleClassName="text-[20px]" />}
            <ProductFeatures
              keyFeatures={model.key_features}
              className="border-t border-gray pb-[20px] pt-8 lg:pb-5 lg:pt-7"
              // titleClassName="text-[20px] lg:text-lg font-normal"
            />
            {subScoresWithScores.length > 0 ? (
              <div className="mt-4 border-t border-gray pt-7">
                <div className="mb-[20px] text-xl font-[300] tracking-tight text-navy">Expert Review</div>
                <ul className="flex flex-col gap-[20px] lg:gap-4">
                  {subScoresWithScores.map((subScore, index) => (
                    <SubScore
                      key={subScore?.subScoreKey}
                      subScoreKey={subScore?.subScoreKey}
                      subScore={subScore}
                      className={cx(!isViewMore && index > SCORES_TO_SHOW_INITIALLY && 'hidden', 'border-navy-5')}
                      commentClassName="text-[16px] lg:text-base leading-6 lg:leading-5"
                    />
                  ))}
                </ul>
                <ViewMoreButton
                  isViewMore={isViewMore}
                  negativeStateText="View more scores"
                  positiveStateText="View fewer scores"
                  onViewMoreButtonClick={() => setIsViewMore((currentIsViewMore) => !currentIsViewMore)}
                  className="mt-[40px] lg:mt-8"
                  hidden={(subScoresWithScores?.length || 0) <= SCORES_TO_SHOW_INITIALLY}
                />
              </div>
            ) : null}
          </section>
        </section>
      </div>
    </section>
  );
};

export default ProductCard;
