import * as React from 'react';

import HtLink from 'components/common-n4/ht-link';
import { Loader } from 'components/common-n4/loader';
import SvgIcon from 'components/ui/svg_icon';
import { propped } from 'lib/propped';
import { cx, tw } from 'lib/utils';

import styles from './button.module.scss';

// TODO: make props consistent, e.g. diabled vs isDisabled
// Note: if you come here wondering why you're getting the react key error, it's because you passed
// two children and this treats that as an array that needs keys. Add a key to each child. I tried
// to do it here, but couldn't get it to work.
const Root = React.forwardRef(({ text, srtext, icon, buttonType, isDisabled, onClick, children, className, id }, ref) => {
  if (!text && !icon && !children) return null;

  const Icon = icon;

  return (
    <button
      id={id}
      ref={ref}
      /* eslint-disable react/button-has-type */
      type={buttonType ?? 'button'}
      disabled={isDisabled}
      aria-label={srtext}
      className={cx(styles.button, className)}
      onClick={onClick}
    >
      {children && children}
      {!children && (
        <>
          {Icon}
          <span className={styles.text}>{text}</span>
        </>
      )}
    </button>
  );
});

Root.displayName = 'ButtonRoot';

const Primary = propped(Root, {
  className: styles['button--primary'],
});

const Secondary = propped(Root, {
  className: styles['button--secondary'],
});

const Tertiary = propped(Root, {
  className: styles['button--tertiary'],
});

const LinkButton = propped(Root, {
  className: styles['button--link'],
});

// Note: changed this to an <a> instead of Link because directory messes with history state
// and Link doesn't actually cause a new page load so hitting back was not working.
// Need to figure out if we can use the next router to update state, but I had trouble when
// I first tried it, which is why I switched to using history directly. If we can't fix that
// then maybe pass a flag here that says whether to use Link or not.
//
// Note2: if you come here wondering why you're getting the react key error, it's because you passed
// two children and this treats that as an array that needs keys. Add a key to each child. I tried
// to do it here, but couldn't get it to work.
let timer = null;
const LinkRoot = ({ children, href, disabled, className, rel = 'noopener noreferrer nofollow', shouldOpenNewTab = false }) => {
  const [shouldShowLoader, setShouldShowLoader] = React.useState(false);

  React.useEffect(() => {
    setShouldShowLoader(false);
    return () => {
      if (timer) {
        setShouldShowLoader(false);
        clearInterval(timer);
        timer = null;
      }
    };
  }, []);

  const handleClick = () => {
    setShouldShowLoader(true);
    timer = setInterval(() => setShouldShowLoader(false), 5 * 1000);
    return true;
  };

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <HtLink
      target={shouldOpenNewTab ? '_blank' : '_self'}
      href={href || '#'}
      disabled={disabled}
      onClick={handleClick}
      className={tw(
        '!px-auto flex h-[48px] cursor-pointer items-center justify-center gap-[0.4rem] px-[0.75rem]',
        'py-[1.5rem] text-[16px] leading-[1.32206rem] tracking-[-0.01019rem] laptop:min-w-[205px]',
        disabled && 'pointer-events-none cursor-not-allowed opacity-40',
        className
      )}
      rel={rel}
    >
      {shouldShowLoader ? (
        <div className="flex !w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        children
      )}
    </HtLink>
  );
};

const LinkPrimary = propped(LinkRoot, {
  className: cx(styles['button--primary'], 'justify-center'),
});

const LinkSecondary = propped(LinkRoot, {
  className: cx(styles['button--secondary'], 'justify-center'),
});

const LinkTertiary = propped(LinkRoot, {
  className: cx(styles['button--tertiary'], 'justify-center'),
});

const IconButton = ({ icon, className, onClick, alt = '', ...props }) => (
  <button className={cx(styles['button--icon'], className)} type="button" onClick={onClick} {...props}>
    <SvgIcon icon={icon} aria-hidden alt={alt} />
  </button>
);

const IconButtonLink = ({ icon, className, href, shouldOpenNewTab = false, rel = 'noopener noreferrer nofollow', alt = '', ...rest }) => (
  // lint rule doesn't like target blank without noreferrer due to security, so don't do that
  // eslint-disable-next-line react/jsx-no-target-blank
  <HtLink className={className} href={href || '#'} rel={rel} target={shouldOpenNewTab ? '_blank' : '_self'} {...rest}>
    <SvgIcon icon={icon} aria-hidden alt={alt} />
  </HtLink>
);

export const Button = {
  Primary,
  Secondary,
  Tertiary,
  LinkPrimary,
  LinkSecondary,
  LinkTertiary,
  Icon: IconButton,
  IconLink: IconButtonLink,
  LinkButton,
};
