import React from 'react';

import { Button } from 'components/common-n4/button';
import { useOncePerPage } from 'hooks';
import { fetchApi } from 'lib/ht_api';
import Logger from 'lib/logger';
import { normalizeUrl } from 'lib/utils';
import type { Release, Model, HearingAid, Price } from 'types/release';

import { ProviderFlow, CTASegments, recordAB, getCtaText } from './provider-pop-up';
import { Event } from './provider-pop-up/types';

const log = Logger({ category: 'price-button' });
/*
                    R
          TL1                TL2
  HA1(FF1) HA2(FF2)  HA3(FF1)  HA4 (FF3)

  For the purpose of showing the popup:
    if the card is showing a hearing aid:
      HA1 is a go if we have HA1 or TL1
      HA2 is a go if we have HA2 or TL1
      HA3 is a go if we have HA3 or TL2
      HA4 is a go if we have HA4 or TL2

    if the card is showing a form factor (model):
      FF3 is a go if we have TL1 or TL2
      FF2 is only if we have TL1
      FF3 is only if we have TL2

  For the purpose of showing the starting price:
    All of the above applies, the only difference
    is the ha price is used if there is one.

*/

interface PriceButtonProps {
  release: Release;
  model?: Model;
  hearingAid?: HearingAid;
  price?: Price;
  text?: string;
  className?: string;
  onModalOpenChange?: (isOpening: boolean) => void;
  noGeo: boolean;
  origin: string;
}

interface PriceWithLoading {
  price?: Price;
  loading: boolean;
}

const segmentOrigin = 'button';

const PriceButton: React.FC<PriceButtonProps> = ({ release, model, hearingAid, price, text, className, onModalOpenChange, noGeo, origin }) => {
  const [geoPrice, setGeoPrice] = React.useState<PriceWithLoading>({ price, loading: !price });
  const [isProviderShowing, showProvider] = React.useState<boolean>(false);
  const [segment, setSegment] = React.useState(-2);
  const useOnce = useOncePerPage();

  React.useEffect(() => {
    setGeoPrice({ price, loading: !price });
  }, [price]);

  if (useOnce(`price-button-${segment}`)) {
    if (segment >= 0) {
      recordAB(Event.ButtonViewed, segment, segmentOrigin);
    }
  }

  React.useEffect(() => {
    if (typeof window.localStorage !== 'undefined') {
      let seg: number | null = null;
      if (localStorage.getItem('pf-segment')) {
        seg = parseInt(localStorage.getItem('pf-segment')!, 10);
        if (seg < 0 || seg > CTASegments[segmentOrigin].length - 1) {
          seg = null;
        }
      }
      if (seg === null) {
        seg = Math.floor(Math.random() * CTASegments[segmentOrigin].length);
        window.localStorage.setItem('pf-segment', seg.toString());
      }
      setSegment(seg);
    } else {
      setSegment(-1);
    }
  }, []);

  React.useEffect(() => {
    if (price || noGeo) {
      // If we pass in price, assume we don't need to get a geo-coded price
      return;
    }
    const fn = async () => {
      const result = await fetchApi({
        path: `releases/${release.slug}/price`,
        variables: {
          modelId: model?.id,
          hearingAidId: hearingAid?.id,
          origin: `widgets/price-button.js[${origin}]`,
        },
        fallback: 'nope',
        log,
        origin: `widgets/price-button.js[${origin}]`,
      });
      setGeoPrice({ loading: false, price: result === 'nope' ? undefined : result });
    };
    fn();
  }, [release.slug, model?.id, hearingAid?.id, price, noGeo, origin]);

  const handleSearch = () => {
    showProvider(true);
    if (onModalOpenChange) {
      onModalOpenChange(true);
    }
  };

  const onClose = () => {
    showProvider(false);
    if (onModalOpenChange) {
      onModalOpenChange(false);
    }
  };

  if (geoPrice.price?.provider_search) {
    let cta;
    if (segment === -2) {
      cta = 'Loading...';
    } else if (segment === -1) {
      cta = 'Find Care';
    } else if (segment >= 0) {
      cta = getCtaText(release.name, segment, segmentOrigin);
    } else {
      console.warn('Segment is unknown %d', segment);
      cta = 'Find Care';
    }
    // display popup
    return (
      <>
        <ProviderFlow
          release={release}
          model={model}
          hearingAid={hearingAid}
          onClose={onClose}
          isModalOpen={isProviderShowing}
          origin={origin}
          segment={segment}
          segmentOrigin={segmentOrigin}
        />
        <Button.Primary className={className || '!w-full'} onClick={handleSearch} isFull>
          {cta}
        </Button.Primary>
      </>
    );
  }

  if (geoPrice.price?.url) {
    return (
      <Button.LinkPrimary
        className={className || '!w-full'}
        href={normalizeUrl({ url: geoPrice.price.url, origin: `price-button[${origin}]#1` })}
        isFull
        shouldOpenNewTab
      >
        {text || 'Check latest prices'}
      </Button.LinkPrimary>
    );
  }

  if (geoPrice.price) {
    return (
      <Button.LinkPrimary
        className={className || '!w-full'}
        href={normalizeUrl({ url: release.path, origin: `price-button[${origin}]#2` })}
        isFull
        shouldOpenNewTab
      >
        Check latest prices
      </Button.LinkPrimary>
    );
  }

  if (noGeo) {
    return (
      <Button.LinkPrimary className={className || '!w-full'} href={normalizeUrl({ url: release.path, origin: `price-button[${origin}]#3` })} isFull>
        Price Not Available
      </Button.LinkPrimary>
    );
  }

  return (
    <Button.LinkPrimary
      className={className || '!w-full'}
      href={normalizeUrl({ url: release.path, origin: `price-button[${origin}]#4` })}
      isFull
      disabled={geoPrice.loading}
    >
      {geoPrice.loading ? 'Checking latest prices...' : 'Check latest prices'}
    </Button.LinkPrimary>
  );
};

export default PriceButton;
