import React from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import { ImageSource } from 'components/common-n4/image/types';
import PriceWidget from 'components/widgets/price';
import { normalizeUrl, tw } from 'lib/utils';
import { type Price } from 'types/release';

import { Panel } from './panel';

interface ProductType {
  slug: string;
  image: ImageSource;
  name: string;
  full_name: string;
  description: string;
  path: string;
  price: Price;
  score: number;
  social_image: ImageSource;
  open_graph_image: ImageSource;
}

interface ProductProps {
  product: ProductType;
  className?: ClassNameValue;
}

const origin = 'article/featured-produdcts';

const Product: React.FC<ProductProps> = ({ product, className }) => {
  return (
    <div className={tw('flex gap-5', className)}>
      {product.image && (
        <div className="relative shrink-0">
          <HtLink
            href={normalizeUrl({ url: product.path, origin })}
            data-track
            data-event-name="sidebar-product"
            data-event-release={product.slug}
            data-origin={origin}
          >
            <ImageWrapper
              key={product.name}
              image={product.open_graph_image || product.social_image}
              origin={origin}
              imgProps={{ className: 'bg-white rounded-[10px] w-[145px] h-[95px] object-cover border border-navy-10' }}
            />
            {product.score && (
              <CircleScoreFluid
                size="xs"
                amount={product.score}
                progress={(product.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
                className="absolute left-2 top-2 shadow-[1px_2px_2px_0_rgba(0,0,0,0.15)]"
                origin={origin}
              />
            )}
          </HtLink>
        </div>
      )}
      <div className="flex flex-col gap-1">
        <div className="line-clamp-1 text-lg leading-[140%] tracking-tight text-navy">
          <HtLink
            href={normalizeUrl({ url: product.path, origin })}
            data-track
            data-event-name="sidebar-product"
            data-event-release={product.slug}
            data-origin={origin}
          >
            {product.full_name}
          </HtLink>
        </div>
        <div className="line-clamp-2 text-sm font-normal not-italic leading-[1.225rem] tracking-[-0.02625rem] text-[#727c9d]">{product.description}</div>
        <div className="text-sm leading-5 tracking-tight text-lapis">
          <PriceWidget
            prefix="From "
            suffix=" a pair"
            loading="Loading price..."
            releaseSlug={product.slug}
            price={product.price}
            noGeo
            origin={`cms/${origin}`}
          />
        </div>
      </div>
    </div>
  );
};

interface FeaturedProductsProps {
  products: ProductType[];
}

export const FeaturedProducts: React.FC<FeaturedProductsProps> = ({ products }) => {
  return (
    <Panel className="flex flex-col gap-6">
      <ul className="flex gap-2">
        <li>
          <span className="text-xl leading-7 tracking-tight text-navy">Featured Products</span>
        </li>
      </ul>
      <div className="flex flex-col gap-4">
        {products?.map((product, index) => <Product key={index} product={product} className={index === products.length - 1 && 'border-none pb-0'} />)}
      </div>
    </Panel>
  );
};
