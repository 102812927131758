import { lazy, Suspense, useMemo } from 'react';

import { storyblokEditable } from '@storyblok/react';

import { Loader } from 'components/common/loader';
import { cx } from 'lib/utils';

import styles from './faq.module.scss';

import { Accordion } from './faq-accordion/accordion';

const ReactMarkdown = lazy(() => import('react-markdown'));

const FAQ = ({ blok }) => {
  const accordionData = useMemo(
    () =>
      blok?.questions?.map(({ question, answer }) => ({
        header: question,
        content: (
          <Suspense
            fallback={
              <div className={styles.loader}>
                <Loader />
              </div>
            }
          >
            <ReactMarkdown>{answer}</ReactMarkdown>
          </Suspense>
        ),
      })),
    [blok?.questions]
  );

  if (!blok?.questions) return null;

  if (!accordionData?.length) return null;

  return (
    <section {...storyblokEditable(blok)} className={cx(styles['ht-faq'], 'ht-faq')}>
      <Accordion data={accordionData} />
    </section>
  );
};

export default FAQ;
