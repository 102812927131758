import { useMemo } from 'react';

import { motion, useScroll, useTransform } from 'framer-motion';
import NextLink from 'next/link';
import ReactCarousel from 'react-multi-carousel';

import ImageWrapper from 'components/common-n4/image';
import { useIsMobileView } from 'hooks/use_is_mobile_view';
import { useWindowDimensions, WINDOW_DIMENSION_TYPE } from 'hooks/use_window_dimensions';
import { cx, getWindowDimensionType, normalizeUrl } from 'lib/utils';

import 'react-multi-carousel/lib/styles.css';
import styles from './carousel.module.scss';

const CAOUSEL_MOBILE_MAX_WIDTH = 399;

const CustomDot = ({ onClick, active, opacity }) => (
  <div style={{ width: '100%' }} className={styles.underline}>
    <motion.button type="button" onClick={onClick} initial={{ opacity: 1 }} style={{ opacity }}>
      <div className={cx(styles.display, active ? [styles.active] : '')} />
    </motion.button>
  </div>
);

export const Carousel = ({ blok }) => {
  const { width, height } = useWindowDimensions(true);
  const dimensionType = getWindowDimensionType(width);
  const isMobileView = useIsMobileView();
  const items = blok.carousel;
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, height], [1, 0.2]);

  const [carouselWidth, carouselImageWidth, carouselImageHeight] = useMemo(() => {
    switch (dimensionType) {
      case WINDOW_DIMENSION_TYPE.mobile:
        if (width > CAOUSEL_MOBILE_MAX_WIDTH) return [CAOUSEL_MOBILE_MAX_WIDTH, 160, 116];
        return [width - 34, 160, 116];
      case WINDOW_DIMENSION_TYPE.wide:
        return [452, 452, 283];
      default:
        return [360, 360, 225];
    }
  }, [width, dimensionType]);

  const responsive = {
    all: {
      breakpoint: { max: Infinity, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div>
      <ReactCarousel
        responsive={responsive}
        infinite={false}
        arrows={false}
        renderDotsOutside
        showDots
        customDot={<CustomDot opacity={opacity} />}
        className={styles.carousel}
        dotListClass={cx(styles['custom-dot-list'], carouselWidth < CAOUSEL_MOBILE_MAX_WIDTH ? styles['custom-dot-list-small'] : '')}
        swipeable={isMobileView}
        draggable={isMobileView}
      >
        {items.map((v) => {
          const href = v.link.cached_url;
          return (
            <motion.div
              key={v._uid}
              className={styles['item-container']}
              initial={{ opacity: 1 }}
              style={{ width: isMobileView ? '' : carouselWidth, maxWidth: isMobileView ? carouselWidth : '', opacity }}
            >
              <NextLink href={normalizeUrl({ url: href, origin: 'home/hero/bottom/carousel' })} className={styles.item}>
                <div className={styles.text}>
                  <div className={styles.smallText}>{v.small_text}</div>
                  <div className={styles.title}>{v.title}</div>
                </div>
                <div className={styles['image-container']}>
                  <div className={styles.image}>
                    <ImageWrapper
                      image={v.image}
                      sizingProps={{ fixed: true, fixedWidth: carouselImageWidth, fixedHeight: carouselImageHeight }}
                      imgProps={{ className: 'min-w-[110px] xs:min-w-[150px] object-cover' }}
                      origin="home-carousel"
                    />
                    <div className={styles.arrows}>
                      <button href={normalizeUrl({ url: href, origin: 'home/her/bottom/carousel' })} type="button" className={cx(styles.arrow, styles.right)}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.33301 10.8926L16.6663 10.8926" stroke="#142546" strokeWidth="1.24489" strokeLinejoin="round" />
                          <path d="M10 4.25391L16.6667 10.8933L10 17.5327" stroke="#142546" strokeWidth="1.24489" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </NextLink>
            </motion.div>
          );
        })}
      </ReactCarousel>
    </div>
  );
};
