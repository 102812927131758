/* eslint-disable max-len */
import React from 'react';

import * as Popover from '@radix-ui/react-popover';
import { ClassNameValue } from 'tailwind-merge';

import IconInfoSm from 'images/icon-info-sm.svg';
import IconInfo from 'images/icon-info.svg';
import { tw } from 'lib/utils';
import { LevelFeatureDetail, ModelFeatureDetail, ModelFeatureValue, LevelFeatureValue } from 'types/release';

import { getValueForFeature } from './utils';

import { Tooltip, TooltipDescription, TooltipSubtitle, TooltipTitle } from '../../tooltip';

interface FeatureProps {
  name?: string | null;
  value?: string | null;
  featureDetail?: LevelFeatureDetail | ModelFeatureDetail;
  featureValues: ModelFeatureValue[] | LevelFeatureValue[];
  className?: ClassNameValue;
  brandName?: string | null;
  type?: string;
  titleClassName?: string;
  valueClassName?: string;
  popoverButtonClassName?: string;
  iconInfoClassName?: string;
  iconInfoSmClassName?: string;
  disablePopover?: boolean;
}

const parseFeatureValue = (featureValue: string) => {
  if (!featureValue) {
    return 'N/A';
  }

  let finalValue = featureValue;

  if (/^\[/i.test(featureValue)) {
    const { value } = JSON.parse(`{"value": ${featureValue}}`);
    finalValue = value.map((val: string | number | undefined) => (
      <React.Fragment key={val}>
        {val}
        <br />
      </React.Fragment>
    ));
  }

  return finalValue;
};

export const Feature: React.FC<FeatureProps> = ({
  name,
  featureDetail,
  featureValues,
  className,
  brandName,
  type,
  titleClassName,
  valueClassName,
  popoverButtonClassName,
  iconInfoClassName,
  iconInfoSmClassName,
  disablePopover = false,
}) => {
  const parsedValue = parseFeatureValue(getValueForFeature(featureValues, featureDetail));
  const description = featureDetail?.proprietary_description || featureDetail?.generic_description;

  return (
    <Popover.Root>
      <div className={tw('py-[16px] lg:pb-4 lg:pt-0', className)}>
        <h6
          className={tw(
            'flex min-h-[22px] items-start gap-2 text-[12px] -tracking-[0.36px] text-navy/60 lg:text-lg lg:leading-[120%] lg:-tracking-[0.54px]',
            titleClassName
          )}
        >
          {name}
          {!disablePopover && (
            <Popover.Trigger asChild>
              <button className={tw('IconButton w-[12px]', popoverButtonClassName)} aria-label="Update dimensions">
                <IconInfo className={tw('mr-2 mt-[2px] hidden lg:block', iconInfoClassName)} />
                <IconInfoSm className={tw('mr-2 mt-[3px] lg:hidden', iconInfoSmClassName)} />
              </button>
            </Popover.Trigger>
          )}
        </h6>
        <p
          className={tw(
            'mt-[2px] text-[16px] leading-[140%] -tracking-[0.48px] text-navy lg:mt-0 lg:mt-[8px] lg:text-[20px] lg:text-xl lg:-tracking-[0.6px]',
            valueClassName
          )}
        >
          {parsedValue}
        </p>
      </div>
      <Popover.Portal>
        <Popover.Content className="PopoverContent" sideOffset={5} side="top">
          <Tooltip>
            {type === 'software' && (
              <>
                <TooltipTitle>{featureDetail?.proprietary_name || featureDetail?.generic_name}</TooltipTitle>
                <TooltipSubtitle>
                  {description} {/*optionName && `Value Shown: ${optionName}`*/}
                </TooltipSubtitle>
                {featureDetail?.disclaimer && featureDetail?.proprietary_description && <TooltipDescription>Description via {brandName}</TooltipDescription>}
              </>
            )}
            {type === 'hardware' && (
              <>
                <TooltipTitle>{featureDetail?.name}</TooltipTitle>
                <TooltipSubtitle>{featureDetail?.description}</TooltipSubtitle>
              </>
            )}
          </Tooltip>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
